<template>
  <div>
    <map-base ref="mapCom" :map="map" />
    <!-- 风羽 -->
    <WindBarbsChart
      v-if="showBarbsChart"
      :img-point="imgPoint"
      @closed="chartClosed"
    />
    <!-- 风场 -->
    <legends class="legend" :legend-type="layerId" />
    <div class="check-items">
      <el-switch
        v-model="showType.distribute_select.checked"
        active-text="默认"
        @change="changDistribute(showType.distribute_select)"
      />
      <el-switch
        v-model="showType.particle_select.checked"
        active-text="粒子"
        @change="changParticle(showType.particle_select)"
      />
      <el-switch
        v-model="showType.gridAngle_select.checked"
        active-text="风羽"
        @change="changGridangle(showType.gridAngle_select)"
      />
      <el-switch
        v-model="showType.gridValue_select.checked"
        active-text="数值"
        @change="changGridvalue(showType.gridValue_select)"
      />
    </div>
  </div>
</template>
<script>
import WindBarbsChart from "../Charts/WindBarbsChart";
import legends from "../legend";
export default {
  name: "Wind",
  props: ["map", "layerId", "st", "param"],
  components: {
    WindBarbsChart,
    legends,
  },
  inject: [],
  data() {
    return {
      showBarbsChart: false,
      imgPoint: null,
      showType: this.st,
    };
  },
  watch: {},
  mounted() {
    console.log(this.layerId + "Create");
  },
  methods: {
    changDistribute(data) {
      this.$emit("changDistribute", data);
    },
    changParticle(data) {
      this.$emit("changParticle", data);
    },
    changGridangle(data) {
      this.$emit("changGridangle", data);
    },
    changGridvalue(data) {
      this.$emit("changGridvalue", data);
    },
    infoWin(param) {
      const showData = {
        风速: param.data.toFixed(3) + "m/s",
        风级: this.getWindLevel(param.data) + "级",
        风向: param.angle.toFixed(3) + "°",
        时间: param.time,
        位置: param.pos,
      };
      const chartParam = {
        type: this.param.type,
        lat: param.coordinate[1],
        lon: param.coordinate[0],
        imglat: param.lat,
        imglon: param.lon,
      };
      const title = "风场";
      if (
        Number(param.data) >= this.param.minValue &&
        Number(param.data) <= this.param.maxValue
      ) {
        this.$refs.mapCom.addSeaWeatherInfo(
          param.coordinate,
          showData,
          title,
          () => {
            this.showChart(chartParam);
          }
        );
      }
    },
    /**
     * 获取风级
     */
    getWindLevel(value) {
      let level = 0;
      if (value <= 0.2) level = 0;
      else if (value <= 1.5) level = 1;
      else if (value <= 3.3) level = 2;
      else if (value <= 5.4) level = 3;
      else if (value <= 7.9) level = 4;
      else if (value <= 10.7) level = 5;
      else if (value <= 13.8) level = 6;
      else if (value <= 17.1) level = 7;
      else if (value <= 20.7) level = 8;
      else if (value <= 24.4) level = 9;
      else if (value <= 28.4) level = 10;
      else if (value <= 32.6) level = 11;
      else level = 12;
      return level;
    },
    /**
     * 显示图表历史数据
     */
    showChart(param) {
      this.imgPoint = param;
      // this.showBarbsChart = true;
    },
    chartClosed() {
      this.showBarbsChart = false;
    },
  },
  beforeDestroy() {
    console.log(this.layerId + "Destroy");
    this.chartClosed();
  },
};
</script>
<style lang="scss" scoped>
.legend {
  position: absolute;
  z-index: 99;
  top: 19%;
  // right: 1rem;
  // transform: translate(0, -35%);
  width: 2.5rem;
}
.check-items {
  position: absolute;
  right: 0rem;
  bottom: 5rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  border: 0.02rem solid #ffffff;
  border-radius: 0.02rem;
  background: rgba(0, 0, 0, 0.4);
  padding: 0.5rem 1rem;

  div {
    margin: 0.06rem 0;
  }

  ::v-deep {
    .el-switch__label {
      color: #ffffff;
    }
    .el-switch__core {
      border-color: rgba(255, 255, 255, 0.4);
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 6px;

      &::after {
        left: 2px;
        border-radius: 25%;
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
    .el-switch.is-checked .el-switch__core {
      border-color: #fdd701;
      background-color: rgba(41, 48, 54, 0.9);

      &::after {
        background-color: #fed900;
        left: 100%;
        margin-left: -18px;
      }
    }
  }
}
</style>
